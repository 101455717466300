@import '~antd/dist/antd.less';

* {
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
}

.ant-form-item-label > label {
	color: #748494;
}

.ant-layout-sider {
	background-color: #1d2b36;
}

#menu-sidebar {
	.ant-menu-item:hover {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		background: #2c3d4a;
	}
}
// .ant-layout-sider-children {
// display: grid;
// grid-template-rows: 64px 1fr auto;
// }

.ant-switch-checked {
	background-color: #56c288;
}

.table-link,
.table-link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.ant-pagination {
	margin-top: 15px;
	display: flex;
}

.ant-pagination-total-text {
	flex: 1;
}

.icon-collapsed {
	svg {
		fill: #ffffff;
	}
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.ant-menu-sub.ant-menu-inline {
	background-color: #1d2b36;
}

// .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//   border-color: #73777a;
// }

// .ant-input-affix-wrapper:focus {
//   border-color: #1d2b36;
//   box-shadow: 0 0 0 2px rgb(0 0 0 / 12%);
// }

// .menu-item-collapsed-active::before {
//   position: absolute;
//   top: 0;
//   right: 0;
//   border-top-left-radius: 10px;
//   border-bottom-left-radius: 10px;
//   background-color: #1ea3d4;
//   content: ".";
//   z-index: 100;
//   width: 7px;
// }

.ant-badge-multiple-words {
	padding: 0 5px !important;
}

.ant-menu .ant-menu-sub .ant-menu-vertical {
	background-color: #1d2b36;
}

.ant-menu-submenu-arrow {
	color: #ffffff;
}

.ant-menu-submenu-popup > .ant-menu {
	background-color: #1d2b36;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}

.ant-menu-inline .ant-menu-item::after {
	display: none;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
	background: #1d2b36 !important;
}

.ant-skeleton-element {
	width: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	background-color: #1d2b36;
	color: #ffffff;
	border-right: none;
}

.ant-menu-item-group-title {
	color: #748494;
}

.ant-menu-item .ant-badge {
	opacity: 1 !important;
}

.ant-form-item {
	margin-bottom: 10px;
}

.ant-breadcrumb {
	display: flex;
}

.ant-breadcrumb li {
	display: flex;
}

.ant-breadcrumb,
.ant-breadcrumb span {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.ant-breadcrumb div {
	color: #ff4a50;
}

.ant-breadcrumb > span:last-child div {
	color: rgba(0, 0, 0, 0.85);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #d81f64 !important;
}

.ant-tabs-ink-bar {
	position: absolute;
	background: #d81f64;
	pointer-events: none;
}

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #748494;
}

::-webkit-scrollbar-thumb:hover {
	background: #748494;
}

@primary-color: #1d2b36;@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #D81F64;@menu-highlight-color: #FFFFFF;